<template>
  <MainForm
    type="edit"
    :tagsids="tags"
    v-on:submit="submit" />
</template>
<script>
import MainForm from './form'
import { mapState } from 'vuex'

export default {
  name: 'pageEdit',
  data() {
    return {}
  },
  computed: {
    ...mapState('tag', {
      tags: state => state.data,
    }),
  },
  created() {
    const vm = this
    vm.$store.dispatch('page/FETCH_ONE', vm.$route.params.id)
  },
  methods: {
    submit() {
      const vm = this
      vm.$store.dispatch('page/UPDATE', vm.$route.params.id).then((res) => {
        vm.$notification.success({
          message: 'Page Updated',
          description: 'Successfully updated.',
        })
        vm.$router.push({ path: '/pages' })
      })
    },
  },
  components: {
    MainForm,
  },
}
</script>
